import { FC } from 'react'
import { ActionsDropdown } from '..'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'
import { TableStyles } from './styles'
import { TransfusionRequestTablePropTypes } from './types'

export const TransfusionRequestTable: FC<TransfusionRequestTablePropTypes> = ({
  headers,
  onSelect,
  filterBy,
  data,
}) => (
  <>
    <TableStyles>
      <thead>
        <tr>
          {headers.map((item, index) => (
            <th key={item} onClick={() => filterBy(index)}>
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item}>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>{item}</td>
            <td>
              <ActionsDropdown onSelect={(action: string) => onSelect(action, item)} />
            </td>
          </tr>
        ))}
      </tbody>
    </TableStyles>
    <SubtilesBar>
      <SubtitleLabel>Legenda:</SubtitleLabel>
      <SubtitleLabel className='done'>Concluído</SubtitleLabel>
      <SubtitleLabel className='done-obs'>Concluído com intercorrência</SubtitleLabel>
      <SubtitleLabel className='in-progress'>Em andamento</SubtitleLabel>
      <SubtitleLabel className='late'>Em atraso</SubtitleLabel>
      <SubtitleLabel className='stand-by'>Não iniciado</SubtitleLabel>
    </SubtilesBar>
  </>
)
