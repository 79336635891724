import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

export const CustomDatePicker = styled(DatePicker)`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 32px;
  color: #4f4f4f;
  font-size: 12px;
  padding: 6px 10px;
  outline: none;
`
