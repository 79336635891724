import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { ReactComponent as ElipsesIcon } from '../../assets/icons/elipses.svg'
import { DropdownStyles } from './styles'
import { ActionDropdownProps } from './types'

const ACTIONS = [
  { value: 'rt', label: 'Visualizar RT' },
  { value: 'sadt', label: 'Visualizar Guia SADT' },
  { value: 'termo-consent', label: 'Termo de consentimento' },
  { value: 'etiq-tipagem', label: 'Gerar etiqueta para tipagem' },
  { value: 'saida-hemocomp', label: 'Gerar saída de hemocomponente' },
  { value: 'recibo-hemocomp', label: 'Gerar recebimento de hemocomponente' },
  { value: 'acomp-transf', label: 'Acompanhamento Transfusional' },
  { value: 'hemovigilancia', label: 'Hemovigilância' },
]

export const ActionsDropdown: FC<ActionDropdownProps> = ({ onSelect }) => (
  <DropdownStyles onSelect={onSelect}>
    <Dropdown.Toggle>
      <ElipsesIcon />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {ACTIONS.map(({ value, label }) => (
        <Dropdown.Item onClick={(e: any) => e.preventDefault()} key={value} href={value}>
          {label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </DropdownStyles>
)
