import { Form, FormSelect } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import styled from 'styled-components'

export const FormInput = styled(DebounceInput)`
  font-size: 12px;
  color: #4f4f4f;
  border-radius: 5px;
  padding: 6px 10px;
  border: 1px solid #c4c4c4;
  outline: none;
  &:focus {
    border-color: #c4c4c4;
    box-shadow: none;
  }
`
export const FormDropdownButton = styled(FormSelect)`
  font-size: 12px;
  color: #4f4f4f;
  border-color: #c4c4c4;
  border-radius: 5px;
  padding: 6px 10px;
  &:focus,
  &:hover,
  &:active {
    border-color: #c4c4c4;
    box-shadow: none;
  }
`

export const DocInput = styled.input`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  height: 25px;
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  &:disabled {
    border-color: rgba(196, 196, 196, 0.25);
  }
  &.borderless {
    border: none;
  }
`

export const DocLabel = styled.label`
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 6px;
`

export const DocTextArea = styled(Form.Control)`
  resize: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  height: 80px;
  width: 100%;
  &:disabled {
    border: none;
  }
`
