import { Col, Row } from 'react-bootstrap'
import { AppDropdown } from '../appDropdown'
import { DocInput, DocLabel } from '../form/styles'
import { PageSection, SubHeader, SubTitle } from '../page/styles'

export const PacientDetailsSection = () => {
  const handleModalidadeChange = (event: string | null) => {
    console.log(event)
  }

  return (
    <PageSection>
      <SubHeader>
        <SubTitle>DADOS DO PACIENTE</SubTitle>
        <SubTitle>N° Atend.: 7854241</SubTitle>
      </SubHeader>
      <Row className='mb-18'>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <DocLabel>Nome do Paciente:</DocLabel>
          <DocInput
            disabled
            type='text'
            className='bold-text'
            value='ROSA OLINDAS DAS CALDAS OLIVEIRA'
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Data de Nasc.:</DocLabel>
          <DocInput disabled type='text' value='15/08/1972' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Idade:</DocLabel>
          <DocInput disabled type='text' value='50 anos' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Sexo:</DocLabel>
          <DocInput disabled type='text' value='Feminino' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>CPF:</DocLabel>
          <DocInput disabled type='text' value='907.158.460-78' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>RG:</DocLabel>
          <DocInput disabled type='text' value='41.547.651-3' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Peso:</DocLabel>
          <DocInput type='number' value='15/08/1972' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Telefone:</DocLabel>
          <DocInput disabled type='text' value='(85) 99632-3205' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <DocLabel>Nome do Mãe:</DocLabel>
          <DocInput disabled type='text' value='Maria de Fátima das Caldas Oliveira' />
        </Col>
      </Row>
      <Row className='mb-18'>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Convênio:</DocLabel>
          <DocInput disabled type='text' value='Empresarial' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Matrícla Convênio:</DocLabel>
          <DocInput disabled type='text' value='9071580-78' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Nª GIH/AIH:</DocLabel>
          <DocInput disabled type='text' value='#123456' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <DocLabel>Clínica:</DocLabel>
          <DocInput disabled type='text' value='Posto de Atendimento 1111 - POSTO P1A' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Enfermaria/APT°:</DocLabel>
          <DocInput disabled type='text' value='Enfermaria' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Leito:</DocLabel>
          <DocInput disabled type='text' value='205' />
        </Col>
      </Row>

      <hr />
      <Row className='mb-18'>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Hb:</DocLabel>
          <DocInput disabled type='text' value='5,8 g/dl' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Ht:</DocLabel>
          <DocInput disabled type='text' value='16,6%' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Plaq:</DocLabel>
          <DocInput disabled type='text' value='160.000/mm³' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>TAP/INR:</DocLabel>
          <DocInput disabled type='text' value='11”-30%/2,5' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>FIBR:</DocLabel>
          <DocInput disabled type='text' value='ABC123456' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Modalidade Transfusão:</DocLabel>
          <AppDropdown
            placeholderText='Selecione'
            options={[
              { label: 'Programada/Reserva', value: '01' },
              { label: 'Rotina', value: '02' },
              { label: 'Urgente', value: '03' },
              { label: 'Emergência', value: '04' },
            ]}
            onSelect={handleModalidadeChange}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Data:</DocLabel>
          <DocInput type='date' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Hist. de transfusão prévia:</DocLabel>
          <AppDropdown
            placeholderText='Selecione'
            options={[
              { label: 'Sim', value: 'sim' },
              { label: 'Não', value: 'nao' },
            ]}
            onSelect={handleModalidadeChange}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Há quanto tempo:</DocLabel>
          <DocInput type='text' />
        </Col>
      </Row>
      <Row className='mb-18'>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>Reação Transfusional Prévia:</DocLabel>
          <AppDropdown
            placeholderText='Selecione'
            options={[
              { label: 'Sim', value: 'sim' },
              { label: 'Não', value: 'nao' },
            ]}
            onSelect={handleModalidadeChange}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <DocLabel>Tipo:</DocLabel>
          <DocInput type='text' value='ABC123456' />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <DocLabel>História Gestacional:</DocLabel>
          <AppDropdown
            placeholderText='Selecione'
            options={[
              { label: 'Sim', value: 'sim' },
              { label: 'Não', value: 'nao' },
            ]}
            onSelect={handleModalidadeChange}
          />
        </Col>
      </Row>
    </PageSection>
  )
}
