import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoginModal } from '..'
import { ReactComponent as HapvidaLogo } from '../../assets/icons/logo.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import {
  HeaderBlock,
  HeaderBtn,
  HeaderStyles,
  MenuButton,
  MenuWrapper,
  TitleStyles,
  UserLabelStyles,
} from './styles'
import { HeaderPropTypes } from './types'

export const Header = ({ toggleMenu }: HeaderPropTypes) => {
  const location = useLocation()

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    handleClose()
  }, [location])
  return (
    <>
      <HeaderStyles>
        <MenuWrapper>
          <MenuButton type='button' onClick={toggleMenu}>
            <MenuIcon />
          </MenuButton>
          <HapvidaLogo />
          <TitleStyles>Sistema de Gestão de banco de sangue</TitleStyles>
        </MenuWrapper>

        <HeaderBlock>
          <UserLabelStyles>Olá,</UserLabelStyles>
          <HeaderBtn onClick={handleShow} type='button'>
            <UserIcon />
          </HeaderBtn>
        </HeaderBlock>
      </HeaderStyles>
      {show && <LoginModal show={show} handleClose={handleClose} />}
    </>
  )
}
