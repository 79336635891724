/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { SubHeader, SubTitle } from '../page/styles'
import { PdfBorder, PdfLabel, PdfText, PdfTitle, PdfWrapper } from './styles'

export const PdfTransfustionRequest = forwardRef<HTMLDivElement | any>((_props, ref) => (
  <PdfWrapper ref={ref}>
    <PdfBorder>
      <PdfTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PdfTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 6,
          marginBottom: 6,
        }}
      >
        <h3 style={{ fontSize: 14 }}>
          Hospital:{' '}
          <span style={{ fontWeight: 'normal', fontSize: 16 }}>Hospital Antonio Prudente</span>
        </h3>
        <span style={{ fontWeight: 'normal', fontSize: 16 }}>Nº Transfusão: #123456</span>
      </div>
      <hr style={{ marginBottom: 8 }} />
      <SubHeader style={{ marginBottom: 6 }}>
        <SubTitle>DADOS DO PACIENTE</SubTitle>
        <SubTitle>N° Atend.: 7854241</SubTitle>
      </SubHeader>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={5}>
          <PdfLabel>Nome do Paciente:</PdfLabel>
          <PdfText className='bold-text'>ROSA OLINDAS DAS CALDAS OLIVEIRA</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>PF:</PdfLabel>
          <PdfText>11”-30%/2,5</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Data de Nasc.:</PdfLabel>
          <PdfText>15/08/1972</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <PdfLabel>Idade:</PdfLabel>
          <PdfText>50 anos</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <PdfLabel>Sexo:</PdfLabel>
          <PdfText>Feminino</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <PdfLabel>Peso:</PdfLabel>
          <PdfText>72Kg</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>CPF:</PdfLabel>
          <PdfText>907.158.460-78</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>RG:</PdfLabel>
          <PdfText>41.547.651-3</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Outros docs:</PdfLabel>
          <PdfText>41.547.651-3</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Telefone:</PdfLabel>
          <PdfText>(85) 99632-3205</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>Nome do Mãe:</PdfLabel>
          <PdfText>Maria de Fátima das Caldas Oliveira</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Convênio: <span>Empersarial</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={5}>
          <PdfLabel>
            Matrícla Convênio: <span>Empersarial</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Nª GIH/AIH: <span>Empersarial</span>
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={6}>
          <PdfLabel>
            Clínica: <span>Posto de Atendimento 1111 - POSTO P1A</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Enfermaria/APT°:<span>Enfermaria</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Leito:<span>205</span>
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Diagnóstico:</PdfLabel>
          <p style={{ fontSize: 14, marginBottom: 4 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis id eligendi omnis est
            ipsum error et provident consequuntur, amet similique aliquid asperiores ducimus
            repudiandae itaque repellat architecto aut officia dolorum.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Indicação da transfusão:</PdfLabel>
          <p style={{ fontSize: 14, marginBottom: 4 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis id eligendi omnis est
            ipsum error et provident consequuntur.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>
            Hb: <span>5,8 g/dl</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>
            Ht: <span>16,6%</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Plaq: <span>160.000/mm³</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>
            TAP/INR: <span> </span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>
            FIBR: <span> </span>
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Modalidade Transfusão:</PdfLabel>
          <PdfText>Programa /Reserva</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Data:</PdfLabel>
          <PdfText>15/06/2018</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Hist. de transfusão prévia:</PdfLabel>
          <PdfText>04 anos</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Há quanto tempo:</PdfLabel>
          <PdfText>04 anos</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>Reação Transfusional Prévia:</PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Tipo:</PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>História Gestacional:</PdfLabel>
        </Col>
      </Row>
      <hr />
      <SubTitle>SOLICITAÇÃO</SubTitle>
      <Row>
        <Col md={8}>
          <table style={{ width: '100%' }}>
            <tr>
              <th>Hemocomponente solicitado </th>
              <th>Quantidade</th>
              <th>Volume</th>
            </tr>
            <tbody>
              <tr>
                <td>Alfreds Futterkiste</td>
                <td>Maria Anders</td>
                <td>Germany</td>
              </tr>
              <tr>
                <td>Alfreds Futterkiste</td>
                <td>Maria Anders</td>
                <td>Germany</td>
              </tr>
              <tr>
                <td>Alfreds Futterkiste</td>
                <td>Maria Anders</td>
                <td>Germany</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Procedimentos Especiais:</PdfLabel>
          <p style={{ fontSize: 14, marginBottom: 4 }}>Lorem ipsum dolor sit amet consectetur</p>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Justificatva:</PdfLabel>
          <p style={{ fontSize: 14, marginBottom: 4 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis id eligendi omnis est
            ipsum error et provident consequuntur.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Etiqueta Amostra:</PdfLabel>
          <PdfText>5,8 g/dl</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={1}>
          <PdfLabel>Data:</PdfLabel>
          <PdfText>16,6%</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Colhida:</PdfLabel>
          <PdfText>160.000/mm³</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>Responsável pela coleta:</PdfLabel>
          <PdfText>11”-30%/2,5</PdfText>
        </Col>
      </Row>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Hemocomp.</th>
            <th>Cód. Produto</th>
            <th>Nº Bolsa</th>
            <th>FLAG</th>
            <th>ABO/Rh</th>
            <th>PC</th>
            <tr>
              <th colSpan={2}>Entrega/Liberação</th>
              <th colSpan={3}>Transfusão</th>
            </tr>
            <tr>
              <th>Hora</th>
              <th>Técnico</th>
              <th>SIM</th>
              <th>NÃO**</th>
              <th>Data</th>
            </tr>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td colSpan={5} />
            <td colSpan={3} />
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td colSpan={5} />
            <td colSpan={3} />
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td colSpan={5} />
            <td colSpan={3} />
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td colSpan={5} />
            <td colSpan={3} />
          </tr>
        </tbody>
      </table>
      <PdfText>**Justificativa pelo cancelamento</PdfText>
      <Row>
        <Col className='d-flex' style={{ alignItems: 'flex-end' }} md={6}>
          <hr style={{ width: '100%' }} />
        </Col>
        <Col md={2}>
          <p style={{ fontSize: 14, marginBottom: 4 }}>CRM:</p>
        </Col>
        <Col md={2}>
          <p style={{ fontSize: 14, marginBottom: 4 }}>Data:</p>
        </Col>
        <Col md={2}>
          <p style={{ fontSize: 14, marginBottom: 4 }}>Hora:</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SubTitle style={{ textAlign: 'center' }}>MÉDICO RESPONSÁVEL PELA AUTORIZAÇÃO</SubTitle>
        </Col>
      </Row>
    </PdfBorder>
  </PdfWrapper>
))
