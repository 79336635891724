import styled from 'styled-components'

export const TableStyles = styled.table`
  margin-top: 30px;
  border: 1px solid #c4c4c4;

  .table-icon-btn {
    background-color: transparent;
    border: none;
  }

  th {
    border: 1px solid #c4c4c4;
    font-size: 11px;
    padding: 4px;
    cursor: pointer;
  }

  tbody {
    tr {
      height: 18px;
    }
    tr:not(:nth-child(2n)) {
      background-color: #e5f0f6;
    }
  }

  td {
    font-size: 11px;
    color: #4f4f4f;
    &.centered {
      text-align: center;
    }
  }
`