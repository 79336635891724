import { useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Pdf from 'react-to-pdf'
import { Block } from '../../components'
import { PageBadge, PageHeader, PageTitle, SubHeader } from '../../components/page/styles'
import { PacientDetailsSection } from '../../components/transfusionRequest/PacientDetails'
import { PdfTransfustionRequest } from '../../components/transfusionRequest/PdfTransfustionRequest'
import { RequestSection } from '../../components/transfusionRequest/Request'

const PdfOptions = {
  orientation: 'portrait',
  unit: 'cm',
  format: [29, 21],
}

export const TransfusionRequestPage = () => {
  const pdfRef = useRef()
  const navigate = useNavigate()

  return (
    <>
      <Block>
        <PageHeader>
          <PageTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PageTitle>

          <div>
            <Button variant='outline-primary'>Nova ficha</Button>
            <Button variant='outline-primary'>Histórico</Button>
          </div>
        </PageHeader>
        <SubHeader>
          <div>
            <p className='bold-text s-12'>Hospital:</p>
            <p className='s-14'>Hospital Antonio Prudente</p>
          </div>
          <PageBadge>Nº RT: #123456</PageBadge>
        </SubHeader>
        <PacientDetailsSection />
        <RequestSection />
        <Row className='h-flex-end'>
          <Col md={1}>
            <Button
              type='button'
              className='w-100'
              onClick={() => navigate(-1)}
              variant='outline-secondary'
              size='sm'
            >
              Voltar
            </Button>
          </Col>
          <Col md={2}>
            <Button type='button' className='w-100' size='sm' variant='outline-danger'>
              Cancelar requisição
            </Button>
          </Col>
          <Col md={2}>
            <Button type='button' className='w-100' size='sm' variant='outline-primary'>
              Editar requisição
            </Button>
          </Col>
          <Col md={2}>
            <Pdf targetRef={pdfRef} filename='requisição-transfusão.pdf' options={PdfOptions}>
              {({ toPdf }: any) => (
                <Button type='button' className='w-100' size='sm' onClick={toPdf}>
                  Imprimir Requisição
                </Button>
              )}
            </Pdf>
          </Col>
        </Row>
      </Block>
      <PdfTransfustionRequest ref={pdfRef} />
    </>
  )
}
