import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { DropDownToggle } from './styles'
import { AppDropdownPropTypes } from './types'

export const AppDropdown: FC<AppDropdownPropTypes> = ({ placeholderText, options, onSelect }) => (
  <Dropdown onSelect={onSelect}>
    <DropDownToggle>{placeholderText}</DropDownToggle>
    <Dropdown.Menu>
      {options.map(({ label, value }) => (
        <Dropdown.Item onClick={(e: any) => e.preventDefault()} key={value} href={value}>
          {label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
)
