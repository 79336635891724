import { FC } from 'react'
import { AppSelectStypes } from './styles'
import { AppSelectPropTypes } from './types'

export const AppSelect: FC<AppSelectPropTypes> = ({ options, onChange, name = '' }) => (
  <AppSelectStypes aria-label='Default select example' name={name} onChange={onChange}>
    {options.map((item) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ))}
  </AppSelectStypes>
)
