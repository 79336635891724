import { Block } from '../block'
import { DoughnutChart } from '../doughnutChart'
import { DoughnutChartRow } from '../doughnutChart/styles'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'

export const TransfusionProcessTime = () => (
  <Block title='Painel de Processos de Tranfusão' className='h-100'>
    <DoughnutChartRow>
      <DoughnutChart
        title='Tempo'
        data={[
          { label: 'Concluído no prazo', value: 1, color: '#2ECC71' },
          { label: 'Em andamento no prazo', value: 2, color: '#0064A9' },
          { label: 'Concluído/em andamento com atraso', value: 2.4, color: '#DD0404' },
        ]}
      />
      <SubtilesBar className='flex-start flex-column'>
        <SubtitleLabel>Legenda:</SubtitleLabel>
        <SubtitleLabel className='done'>Concluído no prazo</SubtitleLabel>
        <SubtitleLabel className='in-progress'>Em andamento no prazo</SubtitleLabel>
        <SubtitleLabel className='danger'>Concluído/em andamento com atraso</SubtitleLabel>
      </SubtilesBar>
    </DoughnutChartRow>
  </Block>
)
