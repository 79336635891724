import { Block } from '../block'
import { DoughnutChart } from '../doughnutChart'
import { DoughnutChartRow } from '../doughnutChart/styles'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'

export const TransfusionProcessPanel = () => (
  <Block title='Painel de Processos de Tranfusão' className='h-100'>
    <DoughnutChartRow>
      <DoughnutChart
        title='Autorização'
        data={[
          { label: 'Concluído', value: 1, color: '#2ECC71' },
          { label: 'Em andamento', value: 2, color: '#0064A9' },
          { label: 'Em atraso', value: 2.4, color: '#E67E22' },
        ]}
      />

      <DoughnutChart
        title='Tipagem'
        data={[
          { label: 'Concluído', value: 1, color: '#2ECC71' },
          { label: 'Em andamento', value: 2, color: '#0064A9' },
          { label: 'Em atraso', value: 2.4, color: '#E67E22' },
        ]}
      />

      <DoughnutChart
        title='Bolsa Enviada'
        data={[
          { label: 'Concluído', value: 1, color: '#2ECC71' },
          { label: 'Em andamento', value: 2, color: '#0064A9' },
          { label: 'Em atraso', value: 2.4, color: '#E67E22' },
        ]}
      />

      <DoughnutChart
        title='Bolsa Recebida'
        data={[
          { label: 'Concluído', value: 1, color: '#2ECC71' },
          { label: 'Em andamento', value: 2, color: '#0064A9' },
          { label: 'Em atraso', value: 2.4, color: '#E67E22' },
        ]}
      />

      <DoughnutChart
        title='Transfusão'
        data={[
          { label: 'Concluído', value: 1, color: '#2ECC71' },
          { label: 'Em andamento', value: 2, color: '#0064A9' },
          { label: 'Em atraso', value: 2.4, color: '#E67E22' },
        ]}
      />
    </DoughnutChartRow>
    <SubtilesBar className='flex-start'>
      <SubtitleLabel>Legenda:</SubtitleLabel>
      <SubtitleLabel className='done'>Concluído</SubtitleLabel>
      <SubtitleLabel className='in-progress'>Em andamento</SubtitleLabel>
      <SubtitleLabel className='late'>Em atraso</SubtitleLabel>
    </SubtilesBar>
  </Block>
)
