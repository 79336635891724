import { FC } from 'react'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as MiniEyeIcon } from '../../assets/icons/mini-eye.svg'
import { TableStyles } from './styles'
import { TablesPropTypes } from './types'

export const ProfilesTable: FC<TablesPropTypes> = ({ headers, data }) => (
  <TableStyles>
    <thead>
      <tr>
        {headers.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <tr key={item.cod}>
          <td>{item.cod}</td>
          <td>{item.profileName}</td>
          <td>{item.status}</td>
          <td style={{ textAlign: 'center' }}>
            <button className='table-icon-btn' type='button'>
              <MiniEyeIcon />
            </button>
          </td>
          <td style={{ textAlign: 'center' }}>
            <button className='table-icon-btn' type='button'>
              <EditIcon />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </TableStyles>
)
