import { ChangeEvent, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as LogoHapvidaIcon } from '../../assets/icons/logo-hapvida.svg'
import { AppSelect } from '../appSelect'
import { DocInput, DocLabel } from '../form/styles'
import { PageTitle } from '../page/styles'
import { LoginModalStyles } from './styles'
import { LoginModalProps, LoginTypes } from './types'

export const LoginModal = ({ handleClose, show }: LoginModalProps) => {
  const [data, setData] = useState<LoginTypes>({})
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setData((cur) => ({ ...cur, [target.name]: target.value }))
  }

  return (
    <LoginModalStyles show={show} onHide={handleClose}>
      <Modal.Body>
        <div className='logo-wrapper'>
          <LogoHapvidaIcon />
          <PageTitle style={{ textTransform: 'uppercase', textAlign: 'center' }}>
            Sistema de Gestão de banco de sangue
          </PageTitle>
        </div>
        <div>
          <Form.Check
            inline
            label='Unidade Recife'
            name='group1'
            type='radio'
            id='inline-radio-1'
            className='fs-13'
          />
          <Form.Check
            inline
            label='Demais filiais'
            name='group1'
            type='radio'
            id='inline-radio-2'
            className='fs-13'
          />
        </div>
        <hr />
        <DocLabel>Código do Perfil:</DocLabel>
        <AppSelect
          options={[
            { label: '1 - Médico', value: '1' },
            { label: '2 - Enfermagem', value: '2' },
            { label: '3 - Administração', value: '3' },
            { label: '4 - Controle', value: '4' },
          ]}
          onChange={(e) => console.log(e)}
        />
        <DocLabel htmlFor='login'>Nome de usuário:</DocLabel>
        <DocInput
          type='text'
          name='login'
          className='modal-input text'
          placeholder='Login'
          onChange={handleChange}
        />
        <div className='modal-input-wrapper'>
          <DocLabel htmlFor='password'>Senha:</DocLabel>
          <DocInput
            type={showPassword ? 'text' : 'password'}
            name='password'
            className='modal-input password'
            placeholder='Senha'
            onChange={handleChange}
          />
          <button
            type='button'
            className='modal-input-btn'
            onClick={() => setShowPassword((cur) => !cur)}
          >
            <EyeIcon />
          </button>
        </div>
        <Row>
          <Col>
            <Form.Check
              inline
              label='Lembrar'
              name='remember-me'
              type='checkbox'
              id='inline-radio-1'
              className='fs-13'
            />
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Link to='/' className='recover-pw'>
              Esqueceu a senha?
            </Link>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant='primary' className='login-btn' onClick={handleClose}>
          Entrar
        </Button>
      </Modal.Footer>
    </LoginModalStyles>
  )
}
