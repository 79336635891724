import { ChangeEvent, useState } from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppSelect, Block } from '../../components'
import { DocInput, DocLabel } from '../../components/form/styles'
import { PageHeader, PageSection, PageTitle } from '../../components/page/styles'
import { ProfilesTable } from '../../components/tables/ProfilesTable'

export const CreateListProfile = () => {
  const navigate = useNavigate()

  const [profiles, setProfiles] = useState([])
  const [addedProfiles, setAddedProfiles] = useState<{ label: string; value: string }[]>([
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
  ])

  const handleProfileStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
  }

  const addProfile = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
  }

  const removeProfile = () => {
    console.log('remove profile')
  }

  return (
    <Block className='create-user-page'>
      <PageHeader>
        <PageTitle>PERFÍS</PageTitle>
      </PageHeader>
      <PageSection>
        <Row className='mb-10'>
          <Col
            className='d-flex flex-dir-col'
            style={{
              justifyContent: 'flex-end',
            }}
            md={3}
          >
            <DocLabel htmlFor='profile-name'>Nome perfíl:</DocLabel>
            <DocInput type='text' className='bold-text' value='Teste' name='profile-name' />
          </Col>
          <Col md={2}>
            <DocLabel htmlFor='token'>Perfís:</DocLabel>
            <AppSelect placeholderText='Perfís' options={profiles} onChange={addProfile} />
          </Col>
          <Col
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
            }}
          >
            <div>
              {addedProfiles.map((item) => (
                <Badge
                  style={{ cursor: 'pointer', marginRight: 2 }}
                  pill
                  bg='secondary'
                  key={item.value}
                  onClick={removeProfile}
                >
                  {item.label}
                </Badge>
              ))}
            </div>

            <p className='subtitle-text'>Clique para remover perfil.</p>
          </Col>
          <Col className='d-flex flex-dir-col h-flex-end' md={1}>
            <DocLabel htmlFor='status'>Status:</DocLabel>
            <AppSelect
              name='status'
              placeholderText='Status'
              options={[
                { label: 'Ativo', value: 'active' },
                { label: 'Inativo', value: 'inactive' },
              ]}
              onChange={handleProfileStatusChange}
            />
          </Col>
        </Row>
      </PageSection>
      <Row className='h-flex-end mb-18'>
        <Col md={2}>
          <Button
            type='button'
            className='w-100'
            onClick={() => navigate(-1)}
            variant='outline-secondary'
            size='sm'
          >
            Voltar
          </Button>
        </Col>
        <Col md={2}>
          <Button type='button' size='sm' variant='primary' className='w-100'>
            Salvar
          </Button>
        </Col>
      </Row>
      <PageHeader>
        <PageTitle>LISTAGEM DE PERFÍS</PageTitle>
      </PageHeader>
      <Row>
        <ProfilesTable
          headers={['Código', 'Nome do perfil', 'Status', 'Visualizar', 'Editar']}
          data={[{ cod: '1', profileName: 'Test', status: 'Ativo' }]}
        />
      </Row>
    </Block>
  )
}
