import { Button, Col, Form, Row } from 'react-bootstrap'
import { AppSelect } from '..'
import { ReactComponent as FingerPrintIcon } from '../../assets/icons/finger-print.svg'
import { DocInput, DocLabel, DocTextArea } from '../form/styles'
import { PageSection, SubHeader, SubTitle, SubTitleSmall } from '../page/styles'

export const RequestSection = () => {
  const handleModalidadeChange = (event: string | null) => {
    console.log(event)
  }

  return (
    <PageSection>
      <SubHeader>
        <SubTitle>Solicitação</SubTitle>
      </SubHeader>
      <Row className='mb-18 reset-row'>
        <Col md={6}>
          <DocLabel>Disgnóstico:</DocLabel>
          <DocInput type='text' value='' className='w-100' />
          <Row>
            <Col md={8}>
              <SubTitleSmall>Hemocomponente:</SubTitleSmall>
            </Col>
            <Col md={2}>
              <SubTitleSmall>Qtd:</SubTitleSmall>
            </Col>
            <Col md={2}>
              <SubTitleSmall>Unid. Med.:</SubTitleSmall>
            </Col>
          </Row>

          <Row className='mb-10'>
            <Col md={8}>
              <DocInput
                type='text'
                placeholder='Concentrado de Hemácias'
                value=''
                className='w-100'
              />
            </Col>
            <Col md={2}>
              <DocInput type='number' value='' />
            </Col>
            <Col md={2}>
              <AppSelect
                options={[
                  { label: 'mg', value: 'mg' },
                  { label: 'ml', value: 'ml' },
                ]}
                onChange={(e) => console.log(e)}
              />
            </Col>
          </Row>
          <Row className='mb-10'>
            <Col md={8}>
              <DocInput
                type='text'
                placeholder='Plasma Fresco Congelado'
                value=''
                className='w-100'
              />
            </Col>
            <Col md={2}>
              <DocInput type='number' value='' />
            </Col>
            <Col md={2}>
              <AppSelect
                options={[
                  { label: 'mg', value: 'mg' },
                  { label: 'ml', value: 'ml' },
                ]}
                onChange={(e) => console.log(e)}
              />
            </Col>
          </Row>
          <Row className='mb-10'>
            <Col md={8}>
              <DocInput
                type='text'
                placeholder='Concentrado de Plaquetas'
                value=''
                className='w-100'
              />
            </Col>
            <Col md={2}>
              <DocInput type='number' value='' />
            </Col>
            <Col md={2}>
              <AppSelect
                options={[
                  { label: 'mg', value: 'mg' },
                  { label: 'ml', value: 'ml' },
                ]}
                onChange={(e) => console.log(e)}
              />
            </Col>
          </Row>
          <Row className='mb-10'>
            <Col md={8}>
              <DocInput type='text' placeholder='Crioprecipitado' value='' className='w-100' />
            </Col>
            <Col md={2}>
              <DocInput type='number' value='' />
            </Col>
            <Col md={2}>
              <AppSelect
                options={[
                  { label: 'mg', value: 'mg' },
                  { label: 'ml', value: 'ml' },
                ]}
                onChange={(e) => console.log(e)}
              />
            </Col>
          </Row>
          <Row className='mb-10'>
            <Col md={8}>
              <DocInput
                type='text'
                placeholder='Plasma Isento de Crio'
                value=''
                className='w-100'
              />
            </Col>
            <Col md={2}>
              <DocInput type='number' value='' />
            </Col>
            <Col md={2}>
              <AppSelect
                options={[
                  { label: 'mg', value: 'mg' },
                  { label: 'ml', value: 'ml' },
                ]}
                onChange={(e) => console.log(e)}
              />
            </Col>
          </Row>
          <hr />
          <SubTitleSmall>Procedimentos Especiais:</SubTitleSmall>
          <Form.Group className='mb-3' controlId='formBasicCheckbox'>
            <Row>
              <Col md={4}>
                <Form.Check type='checkbox' label='Fenotipado' />
              </Col>
              <Col md={4}>
                <Form.Check type='checkbox' label='Pool CP' />
              </Col>
              <Col md={4}>
                <Form.Check type='checkbox' label='Irradiado' />
              </Col>
              <Col md={4}>
                <Form.Check type='checkbox' label='Desleucocitado' />
              </Col>
              <Col md={4}>
                <Form.Check type='checkbox' label='Lavado' />
              </Col>
              <Col md={4}>
                <Form.Check type='checkbox' label='Aférese' />
              </Col>
            </Row>
          </Form.Group>
          <SubTitleSmall>Justificativa:</SubTitleSmall>
          <DocTextArea as='textarea' placeholder='' />
        </Col>
        <Col md={6}>
          <SubTitleSmall>Indicação da transfusão:</SubTitleSmall>
          <DocTextArea as='textarea' placeholder='Leave a comment here' />
          <SubTitleSmall>Contraindicações:</SubTitleSmall>
          <DocTextArea as='textarea' className='hpx-60' placeholder='Leave a comment here' />
          <SubTitleSmall>Justificativa da contraindicação:</SubTitleSmall>
          <DocTextArea as='textarea' className='hpx-60' placeholder='Leave a comment here' />
          <hr />
          <SubTitleSmall>Justificativa de cancelamento:</SubTitleSmall>
          <DocTextArea
            as='textarea'
            className='hpx-40'
            placeholder='Descrição da justificativa para o cancelamento da requisição de transfusão de sangue.'
          />
          <hr />
          <Row className='d-flex space-between'>
            <Col md={4} className='d-flex flex-dir-col h-flex-end'>
              <Row className='mb-10'>
                <Col xs={2}>
                  <FingerPrintIcon />
                </Col>
                <Col>
                  <p className='s-10'>
                    Use o leitor biométrico para assinar o documento digitalmente.
                  </p>
                </Col>
              </Row>
              <Button type='button' size='sm'>
                Realizar Biometria
              </Button>
            </Col>
            <Col md={3} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>CRM:</DocLabel>
              <DocInput type='text' value='ABC123456' />
            </Col>
            <Col md={3} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Data:</DocLabel>
              <DocInput type='date' />
            </Col>
            <Col md={2} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Hora:</DocLabel>
              <DocInput type='time' />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageSection>
  )
}
