import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const TypingStampModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;
  .modal {
    &-dialog {
      max-width: 500px;
      width: 100%;
    }
    &-content {
      width: 100%;
      padding: 15px;

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }

    &-footer {
      border-top: none;
      padding: 0px;
    }
  }
`

export const InfoBlock = styled.div`
  border-radius: 10px;
  border: 1px dashed #a7a7a7;
  padding: 10px;
`

export const Label = styled.p`
  font-size: 11px;
  margin-bottom: 4px;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
`