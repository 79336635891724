import styled from 'styled-components'

export const LayoutStyles = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const LayoutContainerStyles = styled.div`
  padding: 10px;
  background-color: #e5e5e5;
  height: 100%;
`
