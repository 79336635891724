import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from './components'
import { Home, TransfusionRequestPage } from './views'
import { CreateListProfile } from './views/profile'
import { CreateEditUserView, ListUsersView } from './views/users'

export const Router = () => (
  <Routes>
    <Route path='/' element={<Layout />}>
      <Route index element={<Navigate to='/inicio' />} />
      <Route path='/inicio' element={<Home />} />
      <Route path='/requisicao-transfusao/:id' element={<TransfusionRequestPage />} />
      <Route path='/usuario/cadastrar' element={<CreateEditUserView />} />
      <Route path='/usuario/editar/:id' element={<CreateEditUserView />} />
      <Route path='/usuario/lista' element={<ListUsersView />} />
      <Route path='/perfis' element={<CreateListProfile />} />
    </Route>
    <Route path='*' element={<Navigate to='/inicio' />} />
  </Routes>
)
