import styled from 'styled-components'

export const AddButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-size: 12px;
  span {
    margin-left: 4px;
  }
`

export const PdfWrapper = styled.div`
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: -1;
  padding: 16px;
  width: 21cm;
  min-height: 29cm;
  height: 29cm;

  .row {
    margin-bottom: 2px;
  }

  label {
    margin-bottom: 2px;
  }

  input:disabled {
    border: none;
    margin-bottom: 2px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 8px;
    border: 1px solid #dddddd;
  }

  th {
    border: 1px solid #dddddd;
    padding: 6px 10px;
    text-align: center;
  }

  td {
    padding: 2px;
  }

  td,
  th {
    font-size: 12px;
    font-weight: normal;
    height: 16px;
  }

  tbody {
    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }
`

export const PdfBorder = styled.div`
  border: 2px solid #868686;
  padding: 12px 16px;
  width: 100%;
  height: 100%;
`

export const PdfTitle = styled.h2`
  font-size: 14px;
  color: #4f4f4f;
  text-align: center;
`

export const PdfLabel = styled.p`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  span {
    font-weight: normal;
  }
`

export const PdfText = styled.p`
  font-size: 11px;
  margin-bottom: 4px;
`
