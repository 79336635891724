import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const LoginModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;

  .modal {
    &-content {
      width: 500px;
      padding: 34px 68px;
      .logo-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          margin-bottom: 10px;
        }
      }
      .title {
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        &:last-of-type {
          margin-bottom: 12px;
        }
        &.main {
          font-weight: bold;
        }
      }

      .recover-pw {
        color: #4f4f4f;
        font-size: 13px;
      }

      select {
        height: 58px;
        padding: 14px;
      }

      .btn {
        border: none;
        padding: 14px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }

      .login-btn {
        width: 160px;
      }
    }

    &-body {
      padding: 0;
    }

    &-footer {
      border-top: none;
      justify-content: space-between;
      padding: 0px;
    }

    &-input {
      border: 1px solid #d9d9d9;
      padding: 14px;
      border-radius: 4px;
      color: #4f4f4f;
      width: 100%;
      outline: none;
      height: 58px;
      &-wrapper {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
      }
      &-btn {
        position: absolute;
        right: 14px;
        top: 65%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
      }
    }
  }
`
