import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  Block,
  TransfusionalModal,
  TransfusionProcessPanel,
  TransfusionProcessTime,
  TransfusionRequestTable,
  TypingStampModal,
} from '../../components'
import { CustomDatePicker } from '../../components/datePicker/styles'
import { FormDropdownButton, FormInput } from '../../components/form/styles'
import { useAuthContext } from '../../context/auth'
import { INITIAL_VALUES, PRAZO, STATUS, TABLE_HEADERS, TIPO_ATENDIMENTO } from './utils'

export const Home = () => {
  const { auth } = useAuthContext()
  const navigate = useNavigate()

  const [data, setData] = useState(INITIAL_VALUES)
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false)
  const [startDate, endDate] = dateRange

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>, element: string) => {
    setData((cur) => ({ ...cur, [element]: target.value }))
  }

  const handleFilderBy = (header: number) => {
    console.log(header)
  }

  const handleDropDownClick = (action: string, item: string) => {
    if (action === 'rt') {
      navigate(`/requisicao-transfusao/${item}`)
      return
    }

    if (action === 'etiq-tipagem') {
      setIsTicketModalOpen(true)
      return
    }

    if (action === 'acomp-transf') {
      setIsModalOpen(true)
    }
  }

  useEffect(() => {
    console.log(data, dateRange)
  }, [data, dateRange])

  return (
    <>
      <Block title='Acompanhamento das Requisições de Transfusão'>
        <Container fluid>
          <Row className='no-padding-x'>
            <Col>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'n_atendimento')
                }
                placeholder='Nº Atendimento'
                debounceTimeout={500}
              />
            </Col>
            <Col>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'nome_paciente')
                }
                placeholder='Nome do Paciente'
                debounceTimeout={500}
              />
            </Col>
            <Col>
              <CustomDatePicker
                placeholderText='Período'
                selectsRange
                startDate={startDate}
                endDate={endDate}
                id='danteRange'
                onChange={(update: [Date | null, Date | null]) => {
                  setDateRange(update)
                }}
              />
            </Col>
            <Col>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'unidade')}
                placeholder='Unidade'
                debounceTimeout={500}
              />
            </Col>
            <Col>
              <FormDropdownButton
                id='tipoAtendimento'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'tipo_atendimento')
                }
                title='Tipo de atendimento'
              >
                {TIPO_ATENDIMENTO.map(({ value, label }) => (
                  <option key={value} value={value} disabled={!parseInt(value, 10)}>
                    {label}
                  </option>
                ))}
              </FormDropdownButton>
            </Col>
            <Col>
              <FormDropdownButton
                id='status'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'status')}
                title='Status'
              >
                {STATUS.map(({ value, label }) => (
                  <option key={value} value={value} disabled={!parseInt(value, 10)}>
                    {label}
                  </option>
                ))}
              </FormDropdownButton>
            </Col>
            <Col md={2}>
              <FormDropdownButton
                id='prazo'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'prazo')}
                title='Prazo'
              >
                {PRAZO.map(({ value, label }) => (
                  <option key={value} value={value} disabled={!parseInt(value, 10)}>
                    {label}
                  </option>
                ))}
              </FormDropdownButton>
            </Col>
            <Col>
              <Button
                style={{ width: '100%', height: '100%', padding: 0 }}
                variant='outline-primary'
                type='button'
              >
                Buscar
              </Button>
            </Col>
          </Row>
          <Row className='table-row'>
            {loading ? (
              'Buscando...'
            ) : (
              <TransfusionRequestTable
                headers={TABLE_HEADERS}
                filterBy={handleFilderBy}
                data={['test', '1', '2']}
                onSelect={(action, item) => handleDropDownClick(action, item)}
              />
            )}
          </Row>
        </Container>
      </Block>
      <Container fluid>
        <Row className='no-padding-x'>
          <Col className='col' md={8}>
            <TransfusionProcessPanel />
          </Col>
          <Col md={4}>
            <TransfusionProcessTime />
          </Col>
        </Row>
      </Container>
      <TransfusionalModal show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
      <TypingStampModal show={isTicketModalOpen} handleClose={() => setIsTicketModalOpen(false)} />
    </>
  )
}
