import styled from 'styled-components'
import { SideBarStylesPropTypes } from './types'

export const SideBarStyles = styled.aside<SideBarStylesPropTypes>`
  position: fixed;
  background-color: #242625;
  width: 285px;
  height: 100vh;
  padding: 24px;
  z-index: 1;
  transition: left ease-in-out 0.25s;
  left: -100%;
  ${({ isMenuOpen }) => isMenuOpen && 'left:0%'}
`
export const SideBarCloseBtn = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 50px;
  transform: translateX(50%);
`

export const SideBarInfo = styled.div`
  display: flex;
`

export const SideBarThumb = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  h5 {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
  }
`

export const SideBarDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const SideBarUserName = styled.h6`
  color: white;
  font-size: 14px;
  margin-bottom: 4px;
`

export const SideBarUserEmail = styled.p`
  color: #c0bfbd;
  font-size: 14px;
`

