import { FC } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { ModalPropTypes } from '../../types'
import { SubHeader, SubTitle } from '../page/styles'
import { InfoBlock, Label, Text, TypingStampModalStyles } from './styles'

export const TypingStampModal: FC<ModalPropTypes> = ({ show, handleClose }) => (
  <TypingStampModalStyles show={show} onHide={handleClose}>
    <Modal.Body>
      <SubHeader>
        <SubTitle>DADOS PARA O PACIENTE - TIPAGEM</SubTitle>
      </SubHeader>
      <InfoBlock>
        <Row>
          <Col md={8}>
            <Label>Nome do Paciente:</Label>
            <Text className='bold-text'>ROSA OLINDAS DAS CALDAS OLIVEIRA</Text>
          </Col>
          <Col>
            <Label>Nº Atendim.:</Label>
            <Text className='bold-text'>7854241</Text>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label>Data de Nasc.:</Label>
            <Text className='bold-text'>15/08/1972</Text>
          </Col>
          <Col md={3}>
            <Label>Idade:</Label>
            <Text className='bold-text'>50 anos</Text>
          </Col>
          <Col md={3}>
            <Label>Leito:</Label>
            <Text className='bold-text'>Apto. 101</Text>
          </Col>
          <Col md={3}>
            <Label>Data da Coleta:</Label>
            <Text className='bold-text'>22/04/2022</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Unidade Hospitalar:</Label>
            <Text className='bold-text'>Hospital Ant. Prudente</Text>
          </Col>
          <Col>
            <Label>Responsável Coleta:</Label>
            <Text className='bold-text'>Carla Dias</Text>
          </Col>
        </Row>
      </InfoBlock>
    </Modal.Body>
    <Modal.Footer>
      <Button size='sm' variant='outline-secondary' onClick={handleClose}>
        Fechar
      </Button>
      <Button size='sm' variant='primary' onClick={handleClose}>
        Imprimir
      </Button>
    </Modal.Footer>
  </TypingStampModalStyles>
)
