export const TABLE_HEADERS = [
  'Solic.',
  'Autor',
  'Tipagem',
  'Bolsa Env.',
  'Bolsa Rec.',
  'Transf.',
  'Tempo',
  'N° RT',
  'Nº Atend.',
  'Paciente',
  'Tipo de Atend.',
  'Unidade',
  'Local',
  'Ações',
]

export const INITIAL_VALUES = {
  n_atendimento: '',
  nome_paciente: '',
  unidade: '',
  tipo_atendimento: '0',
  status: '0',
  prazo: '0',
}

export const TIPO_ATENDIMENTO = [
  { value: '0', label: 'Tipo de atendimento' },
  { value: '1', label: 'Internação' },
  { value: '2', label: 'Emergência' },
]

export const STATUS = [
  { value: '0', label: 'Status' },
  { value: '1', label: 'Concluído' },
  { value: '2', label: 'Pendente' },
  { value: '3', label: 'Todos' },
]

export const PRAZO = [
  { value: '0', label: 'Prazo' },
  { value: '1', label: 'Dentro do prazo' },
  { value: '2', label: 'Fora do prazo' },
]
