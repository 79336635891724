/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, FC, useContext, useMemo, useState } from 'react'
import { AuthContextProps, AuthContextTypes, AuthTypes } from './types'

const AuthContext = createContext<AuthContextTypes>({
  auth: null,
  setAuth: () => {},
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider: FC<AuthContextProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthTypes | null>(null)

  const authValues = useMemo(() => ({ auth, setAuth }), [setAuth])

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
}
